import session from '@/auth/models/session'
import loginUtils from '@/auth/loginUtils'
import { initTier1Common } from '../common/tier1'

export const initTier1NotCheckout = async (): Promise<void> => {
  // TODO: Defer until viewport is resized to desktop if currently on a mobile viewport
  // FIXME: Lighten this and its dependencies so we don't need jQuery and Backbone in tier 1
  session.on('change:token', () => loginUtils.updateHeaderSignInOutLink())
  // Initialize header text on desktop. DOM is assumed to be ready once this executes
  // because we either defer JavaScript execution or include it after the header.
  loginUtils.updateHeaderSignInOutLink()
  await Promise.all([initTier1Common()])
}
